<template>
    <div :class="$style.identifyUsers">
        <div :class="$style.desc">
            <div
                :class="[
                    $style.pic,
                    userInfo.area.toLocaleUpperCase() === 'EN'
                        ? $style.en
                        : userInfo.area.toLocaleUpperCase() === 'ZH'
                        ? $style.cn
                        : $style.jp
                ]"
            ></div>
            <div :class="$style.content">
                <div :class="$style.title">{{ $t('data_settings.install_user_title') }}</div>
                <div :class="$style.content_desc">{{ $t('data_settings.install_user_description') }}</div>
            </div>
        </div>
        <div :class="$style.code">
            <div :class="$style.code_desc">
                <div :class="$style.code_desc_title">{{ $t('data_settings.install_user_how') }}</div>
                <div :class="$style.code_desc_content">
                    {{ $t('data_settings.install_user_code_description') }}
                    <pt-link type="primary" size="small" dom-type="a" target="_blank" :href="docUrl">{{
                        $t('data_settings.install_ptx_view_instruction')
                    }}</pt-link>
                </div>
            </div>
            <div :class="$style.code_content" v-if="profileInfo.profileSource">
                <code id="code_range">
                    <pre v-html="codeHTML"></pre>
                </code>
                <pt-button size="small" color="white" type="outline" :class="$style.btn_copy" @click="copyValue">{{
                    $t('common.button_copy_code')
                }}</pt-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import codeConfig from './code.config';
import docsUrl from '@common/configs/docsUrl';
export default {
    name: 'IdentifyUsers',

    data() {
        return {
            code: codeConfig.userInstallCode,
            codeHTML: codeConfig.userInstallCodeHTML,
            docUrl: docsUrl.identifyUser
        };
    },

    computed: {
        ...mapGetters('main', ['profileInfo', 'userInfo'])
    },

    methods: {
        copyValue() {
            this.$copyText(this.code).then(this.copyValueSuccess, this.copyValueFail);
            let rangeDiv = document.querySelector('#code_range');
            let selection = window.getSelection();
            let range = document.createRange();
            range.selectNode(rangeDiv);
            selection.removeAllRanges();
            selection.addRange(range);
        },

        copyValueSuccess() {
            this.$message.success(this.$t('common.tracking_code_copyright_tips'));
        },

        copyValueFail() {
            this.$message.error(this.$t('common.tracking_code_copyerror_tips'));
        }
    }
};
</script>

<style lang="scss" module>
@import './code_install.scss';
.identifyUsers {
    @extend .wrapper;
    .pic {
        &.cn {
            background-image: url('~@assets/images/setup/identify_users_code_cn.svg');
        }
        &.en {
            background-image: url('~@assets/images/setup/identify_users_code_en.svg');
        }
        &.jp {
            background-image: url('~@assets/images/setup/identify_users_code.svg');
        }
    }
}
</style>
